<template>
  <section>
    <div data-vjs-player>
      <video
        ref="videoPlayer"
        class="video-js vjs-16-9 vjs-big-play-centered vjs-theme-memoriamtv"
      />
    </div>
  </section>
</template>

<script>
import videojs from 'video.js';
import ducthLang from 'video.js/dist/lang/nl.json';
import 'video.js/dist/video-js.css';
import '@videojs/themes/dist/fantasy/index.css';
import 'videojs-mux';
import qualityLevels from 'videojs-contrib-quality-levels/src/plugin';
import hlsQualitySelector from 'videojs-hls-quality-selector/src/plugin';
import offset from 'videojs-offset/src/plugin';

videojs.registerPlugin('qualityLevels', qualityLevels);
videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);
videojs.registerPlugin('offset', offset);

export default {
  name: 'VideoPlayer',
  emits: ['playing', 'show-click-to-start-message', 'livestream-ended'],
  props: {
    loop: {
      type: Boolean,
      required: false,
    },
    broadcast: {
      type: Object,
      required: true,
    },
    currentBrand: {
      type: Object,
      required: false,
    },
    playerId: {
      Type: String,
      required: false,
    },
    autoPlay: {
      Type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const { clippedStart, clippedStop, playbackToken } = this.broadcast;
    const playerInitTime = Date.now();
    const currentBrandName = this.currentBrand?.name || '';
    const videoTitle = this.broadcast.name || '';
    const videoId = this.broadcast.ulid || '';
    const state = this.broadcast.state || '';
    let { playbackUrl } = this.broadcast;
    const { thumbnailUrl } = this.broadcast;

    if (playbackToken && playbackUrl) {
      playbackUrl = [playbackUrl, '?token=', playbackToken].join('');
    }
    const sources = [
      {
        src: playbackUrl,
        type: 'application/x-mpegURL',
      },
    ];

    videojs.addLanguage('nl', ducthLang);

    this.playerSettings = {
      sources,
      controls: true,
      liveui: true,
      playsinline: true,
      preload: 'metadata',
      language: 'nl',
      loop: this.loop,
      html5: {
        hls: {
          overrideNative: !videojs.browser.IS_SAFARI,
        },
        nativeVideoTracks: videojs.browser.IS_SAFARI,
        nativeAudioTracks: videojs.browser.IS_SAFARI,
      },
      plugins: {
        mux: {
          debug: false,
          respectDoNotTrack: false, // Track browsers even where Do Not Track is enabled
          data: {
            env_key: process.env.VUE_APP_MUX_DATA_KEY, // required

            // Site Metadata
            viewer_user_id: this.playerId, // ex: '12345'
            experiment_name: '', // ex: 'player_test_A'
            sub_property_id: '', // ex: 'cus-1'

            // Player Metadata
            player_name: process.env.VUE_APP_CI_REPO_NAME, // ex: 'My Main Player'
            player_version: `${process.env.VUE_APP_CI_BRANCH}#${process.env.VUE_APP_COMMIT_ID}`, // ex: '1.0.0'
            player_init_time: playerInitTime, // ex: 1451606400000

            // Video Metadata (cleared with 'videochange' event)
            video_id: videoId, // ex: 'abcd123'
            video_title: videoTitle, // ex: 'My Great Video'
            video_series: currentBrandName, // ex: 'Weekly Great Videos'
            video_duration: '', // in milliseconds, ex: 120000

            video_stream_type: state, // 'live' or 'on-demand'
            video_cdn: 'MUX', // ex: 'Fastly', 'Akamai'
          },
        },
      },
    };
    // instantiate Video.js

    this.player = videojs(
      this.$refs.videoPlayer,
      this.playerSettings,
      // function onPlayerReady() {
      //   // eslint-disable-next-line no-console
      //   console.log('onPlayerReady', this);
      // },
    );
    this.player.poster(thumbnailUrl || '/hero.png');
    const that = this;
    this.player.hlsQualitySelector({
      displayCurrentQuality: true,
    });

    this.player.one('play', () => {
      that.$emit('show-click-to-start-message', false);
    });
    this.player.on('play', () => {
      that.$emit('show-click-to-start-message', false);
      that.$emit('playing', true);
    });
    this.player.on('pause', () => {
      that.$emit('show-click-to-start-message', true);
      that.$emit('playing', false);
    });
    this.player.on('ended', function playerEnded() {
      this.posterImage.show();
      that.$emit('livestream-ended', true);
    });

    if (state === 'on_demand' && typeof clippedStart === 'number' && typeof clippedStop === 'number') {
      this.player.offset({ start: clippedStart, end: clippedStop });
    }
    if (this.autoPlay) {
      this.player.play()
        .then(() => { // autoplay was successful!
          that.$emit('show-click-to-start-message', false);
        })
        .catch(() => { // play() failed because the user didn't interact with the document first
          that.$emit('show-click-to-start-message', true);
        });
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};

</script>

<style>
.vjs-theme-memoriamtv {
  --vjs-theme-memoriamtv--primary: #9C1680;
  --vjs-theme-memoriamtv--secondary: #5CC2EF;
}

.vjs-theme-memoriamtv .vjs-big-play-button {
  width: 88px;
  height: 88px;
  background: none;
  line-height: 88px;
  font-size: 80px;
  border: none;
  top: 50%;
  left: 50%;
  margin-top: -44px;
  margin-left: -44px;
  background-color: var(--vjs-theme-memoriamtv--primary);
  background-color: #00000066;
  color: #fff;
  border-radius: 1em;
}

.vjs-theme-memoriamtv:hover .vjs-big-play-button,
.vjs-theme-memoriamtv.vjs-big-play-button:focus {
  background-color: var(--vjs-theme-memoriamtv--primary);
}

.vjs-theme-memoriamtv .vjs-control-bar {
  height: 54px;
}
.vjs-theme-memoriamtv .vjs-button > .vjs-icon-placeholder::before {
  line-height: 54px;
}

.vjs-theme-memoriamtv .vjs-time-control {
  line-height: 54px;
}

/* Play Button */
.vjs-theme-memoriamtv .vjs-play-control {
  font-size: 1.5em;
  position: relative;
}

.vjs-theme-memoriamtv .vjs-volume-panel {
  order: 4;
}

.vjs-theme-memoriamtv .vjs-volume-bar {
  margin-top: 2.5em;
}

.vjs-theme-city .vjs-volume-panel:hover .vjs-volume-control.vjs-volume-horizontal {
  height: 100%;
}

.vjs-theme-memoriamtv .vjs-progress-control .vjs-progress-holder {
  font-size: 1.5em;
}

.vjs-theme-memoriamtv .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.5em;
}

.vjs-theme-memoriamtv .vjs-play-control .vjs-icon-placeholder::before {
  height: 1.3em;
  width: 1.3em;
  margin-top: 0.2em;
  border-radius: 1em;
  border: 3px solid var(--vjs-theme-memoriamtv--secondary);
  top: 2px;
  left: 9px;
  line-height: 1.1;
}

.vjs-theme-memoriamtv .vjs-play-control:hover .vjs-icon-placeholder::before {
  border: 3px solid var(--vjs-theme-memoriamtv--secondary);
}

.vjs-theme-memoriamtv .vjs-play-progress {
  background-color: var(--vjs-theme-memoriamtv--primary);
}

.vjs-theme-memoriamtv .vjs-play-progress::before {
  height: 0.8em;
  width: 0.8em;
  content: '';
  background-color: var(--vjs-theme-memoriamtv--primary);
  border: 4px solid var(--vjs-theme-memoriamtv--secondary);
  border-radius: 0.8em;
  top: -0.25em;
}

.vjs-theme-memoriamtv .vjs-progress-control {
  font-size: 14px;
}

.vjs-theme-memoriamtv .vjs-fullscreen-control {
  order: 6;
}

.vjs-theme-memoriamtv .vjs-remaining-time {
  display: none;
}

.vjs-theme-memoriamtv .vjs-seek-to-live-control {
  line-height: 5.5em;
}

.vjs-theme-memoriamtv .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  bottom: 3.9em;
}
</style>
